
         @import "config.scss";
      
.sticky {
   position: fixed;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 10px;
   bottom: 10px;
   left: 10px;
   border-radius: 25px;
   min-width: 150px;
   background-color: red;
   color: white;
   padding: 10px;
   border-radius: 15px;
   font-family: Montserrat;
   border: none;
   z-index: 1000;
   cursor: pointer;
}
@media (max-width: 600px) {
   .sticky {
      width: 50px;
      height: 50px;
      min-width: 150px;
   }
}
@include media('<=tablet') {
}
