
         @import "config.scss";
      
.container {
   font-family: Montserrat;
   border-radius: 20px;
   max-width: 600px;
   .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 220px;
      gap: 10px;
      text-align: center;
   }
}
