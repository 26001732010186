
         @import "config.scss";
      
@import 'base/reset';
/*@import 'base/fonts';*/
@import 'config';

a {
   @extend %body-1;
   text-decoration: none;
   color: $color-link;

   &:hover {
      color: $color-on-link;
   }
}

.show-modal {
   overflow: hidden;
}

@keyframes fadeIn {
   0% {
      @include opacity(0);
   }
   100% {
      @include opacity(1);
   }
}

@keyframes fadeOut {
   0% {
      @include opacity(1);
   }
   100% {
      @include opacity(0);
   }
}
